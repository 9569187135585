<template>
  <div>
    <div v-if="blabs && blabs.length" class="mt-2">
      <masonry-reactions
        :posts="blabs"
        :replies-counts="repliesCounts"
        :total-posts="totalBlabs"
        @loadMore="loadMore"
      />
    </div>
    <div v-else class="no-data">No reactions yet</div>

    <blab-details-modal
      :is-open="openBlabAsModal"
      :blabid="blabid"
      @dismiss-modal="closeBlabAsModal"
      @edit="openEditBlab"
      @profile="openProfile"
      @details="openDetailsPage"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BlabCard from '@/shared/components/BlabCardsmall.vue';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import MasonryReactions from '@/shared/components/Posts/MasonryReactions.vue';

import { getCountofBlabReplies, getCountofReplies } from '@/shared/actions/blabs';
import BlabSunshineCard from '@/shared/components/BlabSunShineCardVertical.vue';
import BlabRepliesCard from '@/shared/components/BlabRepliesCard.vue';

@Options({
  name: 'InfiniteBlabs',
  components: {
    BlabCard,
    BlabDetailsModal,
    BlabSunshineCard,
    BlabRepliesCard,
    MasonryReactions,
  },
})
export default class InfiniteBlabs extends Vue {
  @Prop() public blabs!: any;
  @Prop() public currentTab!: string;
  @Prop() public paging!: any;
  @Prop() public totalBlabs!: number;

  @Watch('blabs', { immediate: true })
  blabsChanged() {
    if (get(this.blabs, 'length')) {
      this.fetchBlabReplies();
      this.fetchRepliesCounts();
    }
  }

  blabRepliesCount = {};
  repliesCounts = {};

  public isEdit = false;

  get = get;
  blabid = '';
  openprofile = false;
  openBlabAsModal = false;

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public loadMore() {
    this.$emit('load');
  }

  public openBlabModal(id: string) {
    const router = useRouter();
    this.blabid = id;
    this.openBlabAsModal = true;
    router.replace({ name: 'reactions', query: { ...router.currentRoute.value.query, id } });
  }

  public openDetailsPage() {
    const router = useRouter();
    this.openBlabAsModal = false;
    router.push({ name: 'post-details', params: { id: this.blabid } });
  }

  public onReaction(index: number, reaction: any) {
    this.blabs[index] = {
      ...this.blabs[index].content_object,
      ...reaction.updatedReactionsData,
    };
    this.$emit('updated');
  }

  public openEditBlab(edit: boolean, id: string) {
    const router = useRouter();
    this.isEdit = edit;
    if (this.isEdit) {
      this.blabid = '';
      this.openBlabAsModal = false;
      router.push({ name: 'edit-post', params: { id } });
    }
  }

  public openProfile(openprofile: boolean) {
    this.blabid = '';
    this.openprofile = openprofile;
    if (this.openprofile) {
      this.openBlabAsModal = false;
    }
  }

  public closeBlabAsModal(val?: any) {
    this.openBlabAsModal = false;
    this.$nextTick(() => (this.blabid = ''));
    if (get(val, 'detail.role') === 'backdrop') {
      this.closeBlabModalRoute();
    }
  }

  public closeBlabModalRoute() {
    const router = useRouter();
    router.push({ name: 'reactions', query: { tab: this.currentTab } });
  }

  public async fetchBlabReplies() {
    this.blabRepliesCount = {};
    const resp = await getCountofBlabReplies(
      this.blabs.filter((x: any) => get(x, 'content_object.type') === 'blab').map((x: any) => x.content_object.id)
    );
    this.blabRepliesCount = keyBy(resp, 'blab');
  }

  public async fetchRepliesCounts() {
    this.repliesCounts = {};
    const resp = await getCountofReplies(
      this.blabs.filter((x: any) => get(x, 'content_object.type') === 'blabreply').map((x: any) => x.content_object.id)
    );
    this.repliesCounts = keyBy(resp, 'parent_reply_id');
  }

  public async mounted() {
    const router = useRouter();
    if (router.currentRoute.value.query.id) {
      this.openBlabModal(router.currentRoute.value.query.id);
    }
  }
}
</script>

<style scoped lang="sass"></style>
